import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      {/* <video 
        src={images.chefVideo} 
        alt="chef" 
        autoPlay 
        loop 
        muted 
        playsInline 
        style={{ width: '50%', height: 'auto' }} 
      /> */}
      <div className="app__wrapper_info">
      <SubHeading title="Our Team's Word" />
      <h1 className="headtext__cormorant">Our Commitment</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          {/* <img src={images.quote} alt="quote_image" /> */}
          <p className="p__opensans">"Our team at Royal Chicken & Fish is dedicated to delivering quality service, ensuring every customer feels at home.</p>
        </div>
        <p className="p__opensans">Our passion for great food and hospitality is what drives us each day, and we believe in making every moment special for our guests."</p>
      </div>

      <div className="app__chef-sign">
        <p className="p__opensans">Signature</p>
        <img src={images.sign} alt="sign" />
      </div>
    </div>
  </div>
    </div>

    
);

export default Chef;
