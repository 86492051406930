import React from 'react';
import './Reviews.css';

const Reviews = () => {
  const googleReviewLink = "https://www.tripadvisor.com/Restaurant_Review-g294446-d25940683-Reviews-Royal_Fish_Chicken-Tirana_Tirana_County.html"; // Replace with your actual Google review link

  return (
    <div className="app__reviews">
      <h1 className="headtext__cormorant">What Our Clients Say</h1>
      <div className="app__reviews-container">
        <div className="app__review">
          <p className="p__opensans">“The best fish and chicken I've ever had! Highly recommend!”</p>
          <h4 className="p__cormorant">- Anna</h4>
        </div>
        <div className="app__review">
          <p className="p__opensans">“Amazing service and delicious food. A must-visit!”</p>
          <h4 className="p__cormorant">- Mery</h4>
        </div>
        <div className="app__review">
          <p className="p__opensans">“Every bite is a delight! Will definitely come back.”</p>
          <h4 className="p__cormorant">- Alex Johnson</h4>
        </div>
      </div>
      <a href={googleReviewLink} target="_blank" rel="noopener noreferrer">
        <button className="custom__button">Leave a Review</button>
      </a>
    </div>
  );
};

export default Reviews;
