import React from 'react';
import { SubHeading } from '../../components';
import headerVideo from '../../assets/header.mp4'; // Adjust path as needed
import './Header.css';

const Header = () => {
  const scrollToMenu = () => {
    const menuSection = document.getElementById('menu');
    if (menuSection) {
      menuSection.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the menu
    }
  };

  return (
    <div className="app__header app__wrapper section__padding" id="home">
      <div className="video-background">
        <video autoPlay loop muted playsInline>
          <source src={headerVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay" />
      </div>
      
      <div className="app__wrapper_info">
        <SubHeading title="Chase the new flavour" />
        <h1 className="app__header-h1">Royal Fish & Chicken</h1>
        <p className="p__opensans">
          Experience the best in quality and taste with our fresh fish and chicken dishes.
        </p>
        <button type="button" className="custom__button" onClick={scrollToMenu}>Explore Menu</button>
      </div>
    </div>
  );
};

export default Header;
