import React, { useEffect, useState } from 'react';
import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Intro, Laurels, SpecialMenu } from './container';
import { Navbar } from './components';
import Loader from './Loader'; // Import the loader component
import './App.css';


const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Duration for loader (3 seconds)

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div>
      {loading ? (
        <Loader /> // Show loader while loading
      ) : (
        <>
          <Navbar />
          <Header />
          <AboutUs />
          <SpecialMenu />
          <Chef />
          <Intro />
          <Laurels />
          
          <Gallery />
          <FindUs />
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
