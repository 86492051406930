import React, { useEffect } from 'react';
import { meal, mealMobile } from '../../constants'; // Adjust path as necessary
import './Intro.css';

const Intro = () => {
  const [playVideo, setPlayVideo] = React.useState(true); // Default to true for autoplay
  const vidRef = React.useRef();
  const isMobile = window.innerWidth <= 768; // Adjust the width as needed

  const videoSrc = isMobile ? mealMobile : meal; // Choose video based on screen size

  useEffect(() => {
    const video = vidRef.current;

    // Ensure the video preloads and is ready to play
    const handleVideoLoad = () => {
      if (playVideo) {
        if (isMobile && video.currentTime < 1) {
          video.currentTime = 0; // Start at the 1-second mark for mobile
        }
        video.play();
      }
    };

    // Add event listener to ensure the video starts playing immediately when loaded
    video.addEventListener('loadeddata', handleVideoLoad);

    // Ensure the video keeps playing by handling 'pause' event
    const ensurePlaying = () => {
      if (video.paused) {
        video.play();
      }
    };

    // Listen for pause events and force it to play
    video.addEventListener('pause', ensurePlaying);

    return () => {
      // Cleanup event listeners when component unmounts
      video.removeEventListener('loadeddata', handleVideoLoad);
      video.removeEventListener('pause', ensurePlaying);
    };
  }, [playVideo, isMobile]);

  return (
    <div className="app__video">
      <video
        ref={vidRef}
        src={videoSrc}
        type="video/mp4"
        loop
        autoPlay
        muted
        preload="auto"
        playsInline // Prevents mobile fullscreen by keeping the video inline
        style={{ pointerEvents: 'none', width: '100%', height: 'auto' }} // Ensures the video stays within the layout
      />
    </div>
  );
};

export default Intro;
