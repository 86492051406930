import images from './images';


const wines = [
  {
    title: 'Burger & Snacks Menu',
    price: '460L',
    tags: '4 fileto pule, patate, Coca Cola',
  },
  {
    title: 'Salçiçe Masa Menu',
    price: '460 L',
    tags: 'Salçiçe masa, patate, Coca Cola',
  },
  {
    title: '6 Chicken Nuggets Menu',
    price: '510 L',
    tags: '6 fileto kotoletë, patate, Coca Cola',
  },
  {
    title: 'Onion Rings Menu',
    price: '510 L',
    tags: 'Rrathë qepe, patate, Coca Cola',
  },
  {
    title: 'Chicken Wings Menu',
    price: '540L',
    tags: '4 krahë pule, patate, Coca Cola',
  },
  {
    title: 'Chicken Burger Menu',
    price: '550L',
    tags: 'Chicken burger, patate, Coca Cola',
  },
  {
    title: '12 Chicken Nuggets Menu',
    price: '770L',
    tags: '12 fileto kotoletë, patate, Coca Cola',
  },
  {
    title: 'Tower Burger Menu',
    price: '750L',
    tags: 'Tower Burger, patate, Coca Cola',
  },
 
];

const cocktails = [
  {
    title: 'Tower Burger Menu',
    price: '750L',
    tags: 'Tower Burger, patate, Coca Cola',
  },
  {
    title: "Panini Sardele Menu",
    price: '410L',
    tags: 'Panini sardele, patate, Coca Cola',
  },
  {
    title: 'Panini Strips Menu',
    price: '460L',
    tags: 'Panini strips, patate, Coca Cola',
  },
  {
    title: 'Panini Fileto Merluci',
    price: '510L',
    tags: 'Panini fileto merluci, patate, Coca Cola',
  },
  {
    title: 'Panini Karkalec Menu',
    price: '560L',
    tags: 'Panini karkalec, patate, Coca Cola',
  },
  {
    title: 'Racion Qofte Peshku',
    price: '280L',
    tags: 'Racion qofte peshku, 5 copë',
  },
  {
    title: 'Mix Fruta Deti',
    price: '790L',
    tags: 'Qofte peshku, karkalec, kallamarë, merluc, sardele',
  },
  {
    title: 'Racion Sardele',
    price: '180L',
    tags: 'Racion sardele, 100gr',
  },
];

const awards = [
  {
    imgUrl: images.award01,
    title: 'Big Burger',
    subtitle: 'Our classic juicy burger made with the freshest ingredients.',
  },
  {
    imgUrl: images.award02,
    title: 'Crispy Fries',
    subtitle: 'Golden, crispy fries with a perfect crunch.',
  },
  {
    imgUrl: images.award03,
    title: 'Chicken Nuggets',
    subtitle: 'Tender chicken nuggets, always a favorite. ',
  },
  
];

export default { wines, cocktails, awards };
