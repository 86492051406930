import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      {/* <img src={images.F} alt="G_overlay" /> */}
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
        Welcome to Royal Fish and Chicken, your go-to destination for delicious seafood and crispy chicken. Our journey began with a passion for quality and flavor, ensuring every dish is crafted with the freshest ingredients. We believe that great food brings people together, creating memorable moments for families and friends alike.

Our diverse menu features bold flavors and authentic recipes that highlight the best of both worlds. At Royal Fish and Chicken, we pride ourselves on exceptional service and a commitment to our community. Join us and experience the royal taste that sets us apart!.
        </p>
        {/* Removed Know More button */}
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
        At Royal Fish and Chicken, our story began with a simple love for good food. With a family passion for culinary traditions, we created a place where everyone could enjoy the finest seafood and chicken dishes. Our commitment to quality means sourcing only the freshest ingredients, ensuring every meal is a delight.

From our humble beginnings, we have grown into a beloved local establishment, known for our warm atmosphere and exceptional flavors. Each recipe is a celebration of our heritage, passed down through generations, with a royal touch in every bite. Join us as we continue to share our journey, one delicious dish at a time!
        </p>
        {/* Removed Know More button */}
      </div>
    </div>
  </div>
);

export default AboutUs;
