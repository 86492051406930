import React, { useEffect, useState } from 'react';
import './Loader.css';
import logo from './assets/logo.png'; // Adjust the path based on where the image is located

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Change the duration as needed (3 seconds in this example)

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div className={`loader ${loading ? '' : 'loader--fade'}`}>
      <img src={logo} alt="Logo" className="loader__logo loader__logo--pulse" />
    </div>
  );
};

export default Loader;
