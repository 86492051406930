import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">Tirana, Albania, 21 Dhjetori</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Opening Hours</p>
        <p className="p__opensans">Every Day from 10:00 AM - 12:00 AM </p>
        
      </div>
      <a href="https://www.bing.com/search?q=royal+fish+and+chicken&pc=GD01&form=GDAVST&ptag=6158" target="_blank" rel="noopener noreferrer">
        <button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Click to Map</button>
      </a>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;