import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

ReactDOM.render(<App />, document.getElementById('root'));

