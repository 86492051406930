import React from 'react';
import { FiFacebook, FiInstagram } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';
import logo1 from '../../assets/Logo1.png'; // Import your logo

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">Tirana, Albania</p>
        <p className="p__opensans">
          <a href="tel:+355673131331">+355 67 31 31 331</a>
        </p>
        <p className="p__opensans">
          <a href="tel:+355683131331">+355 68 31 31 331</a>
        </p>
        <p className="p__opensans">
          <a href="tel:+355693131331">+355 69 31 31 331</a>
        </p>
      </div>

      <div className="app__footer-links_logo">
        {/* <img src={images.gericht} alt="footer_logo" /> */}
        <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a href="https://www.facebook.com/royalfishchicken.al/?locale=ro_RO" target="_blank" rel="noopener noreferrer">
            <FiFacebook />
          </a>
          <a href="https://www.instagram.com/royalfishchicken/" target="_blank" rel="noopener noreferrer">
            <FiInstagram />
          </a>
         
        
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Everyday of the week</p>
        <p className="p__opensans">10:00 am - 12:00 am</p>
      </div>
    </div>

    <div className="footer__copyright">
      <img src={logo1} alt="Company Logo" style={{ height: '100px', marginBottom: '50px' }} /> {/* Add your logo */}
      <p className="p__opensans">
        <a href="https://absolutsystem.com/" target="_blank" rel="noopener noreferrer">
          Created by Absolut Technology System <br /><span>All Rights Reserved © 2024</span>
        </a>
      </p>
    </div>
  </div>
);

export default Footer;